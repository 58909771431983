<template>
    <div v-if="tags && tags.length > 0" class="tags">
        <IndexTag v-for="(loopTag, index) in tags" :key="'tags_' + index"  :tag="loopTag"/>
    </div>
</template>

<script setup>
const route = useRoute();
const tag = route.params.tag;

const tags = shallowRef([]);

if (tag) {
    const {data} = await useFetch("/api/tag/similar", {
        query: {
            tag: tag,
        },
        ignoreResponseError: true,
        transform: (data) => {
            for (const fetchedTag in data.tags) {
                delete data.tags[fetchedTag].similar;
            }
            return data;
        }
    });

    if (data.value && data.value.tags) {
        tags.value = data.value.tags;
    }
}

if (!tags.value || tags.value.length === 0) {
    const {data} = await useFetch("/api/tag", {
        query: {
            limit: 25,
        },
        pick: ["popularTags"],
        ignoreResponseError: true
    });

    if (data.value && data.value.popularTags) {
        tags.value = data.value.popularTags;
    }
}
</script>